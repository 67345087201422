import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


export default function ActionAreaCard({ titel, content, link,image}) {
  return (
    <Card  sx={{
        border: '4px solid #ffffff',
        boxShadow: '0 0 7px blue',
        textAlign:'start',
        flexWrap:'wrap',
        minHeight:'200px',
        marginTop:'15px',
        marginLeft:'15px',
        overflowX:'auto',
        overflow:'scroll',
        maxWidth: 345,
        '@media (max-width:800px)': {
          margin: '5%',
          overflow:'scroll'
        },
      }}>
      <CardActionArea href={link}>
        <CardMedia
          component="img"
          height='200'
          src={require(`../assets/${image}`)}
          alt="Bildbeschreibung"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {titel}
          </Typography>
          <Typography variant="body2" color="text.secondary">
           <b>{content}</b>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
