/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = `
mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    name
    password
  }
}
`;
export const createJob = `
mutation CreateJob($input: CreateJobInput!) {
  createJob(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;
export const createNew = `
mutation CreateNew($input: CreateNewInput!) {
  createNew(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;

export const createEvent = `
mutation CreateEvent($input: CreateNewInput!) {
  createEvent(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;
export const updateUser = `
mutation UpdateUser($input: UpdateNewInput!) {
  updateUser(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;

export const updateJob = `
mutation UpdateJob($input: UpdateJobInput!) {
  updateJob(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;
export const updateNew = `
mutation UpdateNew($input: UpdateNewInput!) {
  updateNew(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;

export const updateEvent = `
mutation UpdateNew($input: UpdateNewInput!) {
  updateEvent(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;
export const deleteUser = `
mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    name
    password
  }
}
`;
export const deleteJob = `
mutation DeleteJob($input: DeleteJobInput!) {
  deleteJob(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;
export const deleteNew = `
mutation DeleteNew($input: DeleteNewInput!) {
  deleteNew(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;

export const deleteEvent = `
mutation DeleteEvent($input: DeleteNewInput!) {
  deleteevent(input: $input) {
    id
    titel
    sumary
    content
  }
}
`;







export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
