import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InboxIcon from '@mui/icons-material/Inbox';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HouseIcon from '@mui/icons-material/House';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import logo from '../assets/logo.JPG';
import ContactsIcon from '@mui/icons-material/Contacts';
import { blue, green } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { Padding, Window } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import Archive from '@mui/icons-material/Archive';
import uniBild from '../assets/university.jpg';

const drawerWidth = 240;

function UniResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();

  const menuItems = [
    {
      text: 'Home & Contacts',
      icon: <AddHomeWorkIcon color="secondary" />,
      path: '/',
      disabled:false
    },
    {
      text: 'News',
      icon: <NewspaperIcon color="secondary" />,
      path: '/news',
      disabled:false
    },

    {
      text: 'Jobs',
      icon: <HomeRepairServiceIcon color="secondary" />,
      path: '/jobs',
      disabled:false
    },
    {
      text: 'Events',
      icon: <EventIcon color="secondary" />,
      path: '/event',
      disabled:false
    },
    {
      text: 'Logements',
      icon: <HouseIcon color="secondary" />,
      path: '/house',
      disabled:false
    },
    {
      text: 'Saarland Univerity',
      icon: <SchoolIcon color="secondary" />,
      path: '/university',
      disabled:false
    },
    {
      text: 'Archive',
      icon: <Archive color="secondary" />,
      path: '/archive',
      disabled:true
    },
    {
      text: 'Contacts',
      icon: <ContactsIcon color="secondary" />,
      path: '/contacts',
      disabled:true
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          
          <ListItem key={item.text} disablePadding>
            <ListItemButton disabled={item.disabled}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
    className='screen'
      sx={{
       
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        
        
        
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px )` },
          ml: { sm: `${drawerWidth}px` },
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <span style={{
          margin: 10,
        }}>KGS</span>
        <Link href="/">
          <Box
            component="img"
            sx={{
              maxHeight: 50,
            }}
            alt="Logo"
            src={logo}
          />
        </Link>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className='main'
       component='body'
      >





  
<Toolbar ></Toolbar>


<header>
  <h3 style={{
    color:'#9C27B0'
  }} >
    <ul>
        <Box sx={{
            display:'flex',
            flexDirection:'row',
            listStyleType:'none'
        }}>
        <a style={{
            margin:'20px'
        }} href='#ubi'><li>Universität Des Saarlandes</li></a>
        <a style={{
            margin:'20px'
        }} href='#htw'><li>HTW-Saar</li></a>
        </Box>
        
        
    </ul>
  
  </h3>
</header>


  
  <section>
  <h3 id='uni' style={{
      backgroundColor:'#9C27B0'
    }}>Universität des Saarlandes</h3>
   
   <img className='image' src={require('../assets/university.jpg')} />
    
    <p className='p'>
    Die Universität des Saarlandes wurde 1948 mit französischer Unterstützung im Saarland gegründet und verbindet deutsche und französische Bildungstraditionen. 
    Mit einem internationalen Profil wurde sie 1950 zur "europäischen Universität". Die Universität besteht aus sechs Fakultäten, darunter Medizin, Mathematik und
     Informatik, Naturwissenschaftlich-Technische Fakultät, Philosophische Fakultät und Rechtswissenschaftliche Fakultät.
    </p>
    <p className='p'>
 
        Das Studienangebot umfasst etwa 130 Studienfächer, darunter Bachelor- und Masterstudiengänge sowie internationale Studiengänge mit Doppelabschlussmöglichkeiten.
        Die Forschung an der Universität wird durch verschiedene Programme, darunter Transregio-Programme und Sonderforschungsbereiche, gefördert.
    </p>
    <p className='p'>
     Die Universität des Saarlandes ist Teil des Spitzenclusters Software-Cluster, der die digitale Transformation von Unternehmen fördert. In der Forschungsumgebung sind
     renommierte Einrichtungen wie das Deutsche Forschungszentrum für Künstliche Intelligenz (DFKI) und das Fraunhofer-Institut für Zerstörungsfreie Prüfverfahren ansässig.
   </p>
   <p className='p'>
   Leibniz-Preisträger der Universität haben herausragende Beiträge in verschiedenen Disziplinen geleistet. Die Saarländische Universitäts- und Landesbibliothek bietet umfangreiche Literatur und elektronische Ressourcen.

   Der Semesterbeitrag beträgt 318,50 Euro und beinhaltet unter anderem ein Semesterticket für den ÖPNV und Beiträge zu verschiedenen Einrichtungen. Besondere Gebühren gelten für Seniorstudierende und Zweithörer. 
   Die Universität des Saarlandes erhebt seit Januar 2019 keine Exzellenzstrategie-Förderung mehr
   </p>
  </section> 
  <section>

  <h2 id='htw' style={{
      backgroundColor:'#9C27B0'
    }}>HTW-Saar: </h2>
   <img className='image' src={require('../assets/htw_saar.png')} />
        <p className='p'>
       Die Hochschule für Technik und Wirtschaft des Saarlandes (htw saar) entstand durch die Umbenennung der Fachhochschule des Saarlandes im Jahr 1991. Die Hochschule befindet sich in Saarbrücken und hat im April 2009 ihre
       Fachbereichsstruktur auf eine Fakultätenstruktur umgestellt. Sie wurde 2013 für ihre Drittmittel-Einwerbung ausgezeichnet.
    </p>
    <p className='p'>
  <h3 style={{
     backgroundColor:'#9C27B0'
    }}>Fakultäten: </h3>
   
    Fakultät für Architektur und Bauingenieurwesen (AuB):
    Bietet Studiengänge in Architektur und Bauingenieurwesen an.
    Forscht interdisziplinär zur Industriekultur.

    Fakultät für Ingenieurwissenschaften (IngWi):
    Bietet Studiengänge wie Elektrotechnik, Maschinenbau, Kommunikationsinformatik an.
    Starke Forschungsorientierung von der Angewandten Werkstofftechnik bis zu den Neurowissenschaften.

    Fakultät für Sozialwissenschaften (SoWi):
    Studiengänge in Sozialer Arbeit, Pädagogik der Kindheit, Management und Expertise im Pflege- und Gesundheitswesen.
    Forschungsschwerpunkte in Gesundheits-, Pflege- und Alternsforschung, Migration, Integration, Regionalforschung.

    Fakultät für Wirtschaftswissenschaften (WiWi):
    Studiengänge in Betriebswirtschaftslehre, Logistik, Marketing, Wirtschaftsingenieurwesen, Kulturmanagement.
    Forschungsschwerpunkte in Logistik, Digitales Marketing, Kulturmanagement.
    </p>
    <strong><h3 style={{
      backgroundColor:'#9C27B0'
    }}>Studienangebot: </h3>
   </strong>

    <p className='p'>
    
        19 Bachelor- und 26 Master-Studiengänge sowie 12 deutsch-französische Studiengänge.
        Bachelor-Studiengänge u.a. in Architektur, Bauingenieurwesen, Elektrotechnik, Informatik, Maschinenbau.
        Master-Studiengänge u.a. in Architektur, Elektro- und Informationstechnik, Marketing Science, Soziale Arbeit.

    </p>

    <strong><h3 style={{
      backgroundColor:'#9C27B0'
    }}>Standorte: </h3>
   </strong>
    <p className='p'>
    Campus Alt-Saarbrücken, Campus Göttelborn, Campus Rotenbühl, Campus IT-Park.
    <strong><h3 style={{
      backgroundColor:'#9C27B0'
    }}>Studierendenschaft: </h3>
   </strong>
    </p>
    <p className='p'>
    Vertreten durch das Studierendenparlament (StuPa) und den Allgemeinen Studierendenausschuss (AStA).
    Besonderheit: Mensabetrieb wurde lange Zeit vom AStA betrieben, später vom Studierendenwerk Saarland übernommen.
    In den Jahren 2010 bis 2018 gab es konfliktreiche Phasen mit StuPa-Absagen und Ermittlungen gegen den AStA.

    Die htw saar ist laut einer Bewertung von 2023 unter den beliebtesten Hochschulen und Universitäten in Deutschland auf Platz 7.
    </p>
    
    
  </section> 


</Box>


</Box>

);

}

UniResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default UniResponsiveDrawer;