import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InboxIcon from '@mui/icons-material/Inbox';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HouseIcon from '@mui/icons-material/House';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import logo from '../assets/logo.JPG';
import ContactsIcon from '@mui/icons-material/Contacts';
import { blue, green } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { Window } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import Archive from '@mui/icons-material/Archive';

import ImageCard from './ImageCard';
import DefilementContainer from './Partner';


const drawerWidth = 240;

function ResponsiveDrawerHouse(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const navigate = useNavigate();

  const menuItems = [
    {
      text: 'Home & Contacts',
      icon: <AddHomeWorkIcon color="secondary" />,
      path: '/',
      disabled:false
    },
    {
      text: 'News',
      icon: <NewspaperIcon color="secondary" />,
      path: '/news',
      disabled:false
    },

    {
      text: 'Jobs',
      icon: <HomeRepairServiceIcon color="secondary" />,
      path: '/jobs',
      disabled:false
    },
    {
      text: 'Events',
      icon: <EventIcon color="secondary" />,
      path: '/event',
      disabled:false
    },
    {
      text: 'Logements',
      icon: <HouseIcon color="secondary" />,
      path: '/house',
      disabled:false
    },
    {
      text: 'Saarland Univerity',
      icon: <SchoolIcon color="secondary" />,
      path: '/university',
      disabled:false
    },
    {
      text: 'Archive',
      icon: <Archive color="secondary" />,
      path: '/archive',
      disabled:true
    },
    {
      text: 'Contacts',
      icon: <ContactsIcon color="secondary" />,
      path: '/contacts',
      disabled:true
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton disabled={item.disabled}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

 
  

  return (
    <Box
      className='screen'
      component='body'
      sx={{
        /*backgroundImage:`url(${image})`,*/
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <CssBaseline />
      <Box position="absolute">
      <AppBar
        
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px )` },
          ml: { sm: `${drawerWidth}px` },
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <span style={{
          margin: 10,
        }}>KGS</span>
        <Link href="/">
          <Box
            component="img"
            sx={{
              maxHeight: 50,
            }}
            alt="Logo"
            src={logo}
          />
        </Link>
     
      </AppBar>
      
      </Box>
     
    

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
     <Box className='newbox' sx={{
       display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '10px',
       aspectRatio:'5',
        mt: '60px',
        '@media (max-width:800px)': {
          flexDirection: 'column',
          alignItems:'center'
          
        },
     }}>
            {houseData.map((card, index) => (
              
                 <ImageCard key={index} {...card} />
              
             
            ))}  
     </Box>
     <DefilementContainer></DefilementContainer>
      </Box>
      
   
  );
}

ResponsiveDrawerHouse.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawerHouse;

const houseData = [
  {
    title: "Schönes Haus in ruhiger Lage",
    content: "2-3 Zimmer-Wohnung 66123 Saarbrücken-Mitte - St Johann  590 €",
    link: "https://www.kleinanzeigen.de/s-anzeige/2-3-zimmer-wohnung/2687624058-203-392 ",
    image:'wohnung1.png'
  },
  /*{
    title: "Gemütliches Stadthaus",
    content: "Zentral gelegen, nahe Einkaufsmöglichkeiten",
    link: "https://beispiel-link.de/haus2",
    image: "/pfad/zum/bild2.jpg"
  },
  {
    title: "Luxuriöses Anwesen am See",
    content: "Modernes Design mit eigenem Bootssteg",
    link: "https://beispiel-link.de/haus3",
    image: "/pfad/zum/bild3.jpg"
  }*/
];

  /*{
    title: '29.11.2023',
    subheader: 'Nettoyage de la ville',
    content: 'La Communauté Camerounaise De Saarbrücken a La Journé de Nettoyage',
    imageUrl: 'URL for image 2',
    collapseContent: 'Specific content for card 2...',
    path:'/news/cleanday',
  },*/
 
  
  // Füge bei Bedarf mehr Karten-Daten hinzu

