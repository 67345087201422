import { Box } from '@mui/material';
import React from 'react';

function DefilementElement({ link, imageSrc, altText, companyName }) {
  return (
    <Box sx={{
        width:'200px',
        height:'100px'
    }}>
     <a href={link} target="_blank" rel="noopener noreferrer" className="defilement-item">
      <img style={{
            height:'50%'
        }} className='image' src={require(`../assets/${imageSrc}`)} alt={altText} />
      <br />
      <b >{companyName}</b>
    </a>
    
  
    </Box>
   );
}

export default function DefilementContainer() {
  const elements = [
    {
      link: 'https://ssb-security.com/',
      imageSrc: 'bewachungsdients.jpg',
      altText: 'Logo SSB Security',
      companyName: 'SSB Security',
    },
    {
        link: 'https://cave-des-montagnes.com/',
        imageSrc: 'cavedeMontages.jpg',
        altText: 'CAVE DES MONTAGES',
        companyName: 'CAVE DES MONTAGES',
      },
      {
        link: 'https://hausafrika.de/',
        imageSrc: 'hausafrica.jpg',
        altText: 'Logo SSB Security',
        companyName: 'Haus Afrika e. V.',
      },
      {
        link: 'https://www.implantologie-saarbrücken.de/',
        imageSrc: 'implantologie.jpg',
        altText: 'Logo SSB Security',
        companyName: 'Implantologie',
      },
      {
        
        imageSrc: 'logistic.jpg',
        altText: 'Logo SSB Security',
        companyName: 'ST - Logistics',
      },
      {
        link: 'https://www.implantologie-saarbrücken.de/',
        imageSrc: 'implantologie.jpg',
        altText: 'Logo SSB Security',
        companyName: 'Implantologie',
      },
      {
        link: 'https://www.sparkasse-saarbruecken.de/de/home.html',
        imageSrc: 'spartkasse.jpg',
        altText: 'Logo SSB Security',
        companyName: 'Spartkasse Saarbrücken',
      },
      {
        link: 'https://www.familov.com/',
        imageSrc: 'familov.jpg',
        altText: 'Logo SSB Security',
        companyName: 'familov',
      },
      {
        link: 'https://honheart.org/',
        imageSrc: 'handonheart.jpg',
        altText: 'Logo SSB Security',
        companyName: 'Hand on Heart e.V',
      },
      {
        link: 'https://pagservicessarl.com/',
        imageSrc: 'servicesaar.jpg',
        altText: 'Logo SSB Security',
        companyName: 'PAG Services',
      },
      
   
  ];

  return (
    <div className="defilement-texte-container">
      {elements.map((element, index) => (
        <DefilementElement
          key={index}
          link={element.link}
          imageSrc={element.imageSrc}
          altText={element.altText}
          companyName={element.companyName}
        />
      ))}
    </div>
  );
}
