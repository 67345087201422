import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const RecipeReviewCard = ({ titel, sumary,content }) => {
  const [expanded, setExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card 
      /*onClick={() => {
        navigate(path);
      }}*/
      sx={{
        border: '4px solid #dd42f5',
        boxShadow: '0 0 7px blue',
        textAlign:'start',
        flexWrap:'wrap',
        minHeight:'200px',
        maxHeight:'200px',
        marginTop:'15px',
        marginLeft:'15px',
        overflow:'scroll',
        '@media (max-width:700px)': {
          margin: '5%',
          overflowX:'scroll'
        },
      }}
    >
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">R</Avatar>}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<b>{titel}</b>}
       
      />

      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{
          fontFamily:'sans-serif',
          fontSize:'100%',
          color:'black',
        }}>
        
          <details>
            <summary style={{
              color:'#1273de'
            }} >{sumary}</summary>
            <p>{content}</p>
          </details>
        </Typography>
      </CardContent>


      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          
          
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default RecipeReviewCard;
