import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HouseIcon from '@mui/icons-material/House';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import logo from '../assets/logo.JPG';
import ContactsIcon from '@mui/icons-material/Contacts';
import { blue, green } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { Window } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import Archive from '@mui/icons-material/Archive';
import RecipeReviewCard from './NewsCard/CardClass';
import DefilementContainer from './Partner';
import { generateClient } from 'aws-amplify/api';
import { listJobs, listNews } from '../graphql/queries';
import { createJob, createNew } from '../graphql/mutations';
import config from '../amplifyconfiguration.json';
import { Amplify } from 'aws-amplify';
import Button from '@mui/material/Button';
import debounce from 'lodash.debounce';
import NewForm from '../components/Form';


const drawerWidth = 240;
const client = generateClient();

function ResponsiveDrawerJobs(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [newsData, setNewsData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const navigate = useNavigate();

  const handleSearch = async (searchValue) => {
    try {
      const response = await client.graphql({
        query: listJobs,
        variables: {
          filter: {
            sumary: { contains: searchValue }
          },
          limit: 200,
          nextToken: null
        }
      });
      setNewsData(response.data.listJobs.items);
    } catch (error) {
      console.log('Error fetching Jobs:', error);
    }
  };
  const [showForm, setShowForm] = useState(false);

  const handleCreate = async (data) => {
    const news = {
      titel: data.titel,
      sumary: data.sumary,
      content: data.content
    };
    await client.graphql({
      query: createJob,
      variables: { input: data },
    });
    console.log('Form data:', data);
    setShowForm(false); // Schließt das Formular nach dem Einreichen
    handleSearch()
  };
  const debouncedSearch = useCallback(debounce(handleSearch,1000), []);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [newsData]);

  const menuItems = [
    {
      text: 'Home & Contacts',
      icon: <AddHomeWorkIcon color="secondary" />,
      path: '/',
      disabled:false
    },
    {
      text: 'News',
      icon: <NewspaperIcon color="secondary" />,
      path: '/news',
      disabled:false
    },

    {
      text: 'Jobs',
      icon: <HomeRepairServiceIcon color="secondary" />,
      path: '/jobs',
      disabled:false
    },
    {
      text: 'Events',
      icon: <EventIcon color="secondary" />,
      path: '/event',
      disabled:false
    },
    {
      text: 'Logements',
      icon: <HouseIcon color="secondary" />,
      path: '/house',
      disabled:false
    },
    {
      text: 'Saarland Univerity',
      icon: <SchoolIcon color="secondary" />,
      path: '/university',
      disabled:false
    },
    {
      text: 'Archive',
      icon: <Archive color="secondary" />,
      path: '/archive',
      disabled:true
    },
    {
      text: 'Contacts',
      icon: <ContactsIcon color="secondary" />,
      path: '/contacts',
      disabled:true
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton disabled={item.disabled}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

 
  

  return (
    <Box
      className='screen'
      component='body'
      sx={{
        /*backgroundImage:`url(${image})`,*/
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <CssBaseline />
      <Box position="absolute">
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px )` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <span style={{ margin: 10 }}>KGS</span>
            <Link to="/">
              <Box
                component="img"
                sx={{
                  maxHeight: 50,
                }}
                alt="Logo"
                src={logo}
              />
            </Link>
            <Button variant="contained" onClick={() => handleSearch(searchText)}>
              FILTER
            </Button>
            <input type='search' id='searchBar' className="search-input" onChange={(e)=>setSearchText(e.target.value)} value = {searchText} />
              
            <Button variant="contained"  onClick={() => setShowForm(true)} >CREATE</Button>
            <Button variant="contained">DELETE</Button>
            <Button variant="contained">UPDATE</Button>
          </Toolbar>
          {showForm && <NewForm onCreate={handleCreate} />}
        </AppBar>
      </Box>
     
    

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
     <Box className='newbox' sx={{
       display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '10px',
       aspectRatio:'5',
        mt: '60px',
        '@media (max-width:800px)': {
          flexDirection: 'column',
          alignItems:'center'
          
        },
     }}>
            {newsData.map((card, index) => (
              
                 <RecipeReviewCard key={index} {...card} />
              
             
            ))}  
     </Box>
     <DefilementContainer></DefilementContainer>
     
      </Box>
        
   
  );
}

ResponsiveDrawerJobs.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawerJobs;

