import './App.css';
import AboutPage from './components/AboutPage';
import ResponsiveDrawer  from './components/HomeItem';
import ResponsiveDrawerDetails from './components/DetailsToNewsCards/Details';
import  JobsItem  from './components/JobsItem';
import React from 'react'
import RecipeReviewCard from './components/News';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimpleContainer from './components/DetailsToNewsCards/Container';
import ResponsiveDrawerJobs from './components/JobsItem';
import ResponsiveDrawerEvents from './components/Events';
import ResponsiveDrawerHouse from './components/Logements';
import UniResponsiveDrawer from './components/University'; 
import { createTodo, updateTodo, deleteTodo } from './graphql/mutations';











 function App() {

  return (
    
    <div className="App">
      <BrowserRouter>
       <Routes>
        <Route path="/" element={<ResponsiveDrawer  ></ResponsiveDrawer>}/>
        <Route path="/university" element={<UniResponsiveDrawer  ></UniResponsiveDrawer>}/>
        <Route path="/jobs" element={<ResponsiveDrawerJobs/>} />
        <Route path="/news" element={<RecipeReviewCard/>} />
        <Route path="/event" element={<ResponsiveDrawerEvents/>} />
        <Route path="/house" element={<ResponsiveDrawerHouse />}/>
        <Route path="/news/cleanday" element={<ResponsiveDrawerDetails content={"Le Vendredi 14 Novenbre a"}/>} />
      </Routes>
     </BrowserRouter>
    
    </div>


  );
}

export default App;
