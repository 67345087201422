import { Button } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import React, { useState } from 'react';

const Form = ({ onCreate }) => {
  const [formData, setFormData] = useState({
    
    titel: '',
    sumary: '',
    content: ''
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  return (
    <div className="popup" 
    style={{
      position: 'absolute',  // oder 'fixed', wenn du es immer sichtbar willst
      top: 0,  // Das Formular wird direkt über der Toolbar positioniert
      left: 0,
      right: 0,
      zIndex: 2,  // Sehr hoher zIndex, damit es über der Toolbar erscheint
      backgroundColor: "#bf00ff",  // Optional: damit es gut sichtbar ist
      padding: "primary",  // Optional: Padding für das Formular
    }}>
      <div className="popup-inner">
        <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="titel"
              value={formData.titel}
              onChange={handleChange}
              required
              placeholder='Titel'
            />
          <br />
            <input
              type="text"
              name="sumary"
              value={formData.sumary}
              onChange={handleChange}
             
              placeholder='Summary'
              
            />
          <br />
            <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              placeholder='Content'
            />

          <br />
          <Button variant="contained" sx={{ backgroundColor: '#ffffff', color: 'black' }} type='submit' >CREATE</Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
