/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = `
query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    password
  }
}
`;
export const listUsers = `
query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      password
    }
    nextToken
  }
}
`;
export const getJob = `
query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    titel
    sumary
    content
  }
}
`;
export const listJobs = `
query ListJobs(
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      titel
      sumary
      content
    }
    nextToken
  }
}
`;
export const getNew = `
query GetNew($id: ID!) {
  getNew(id: $id) {
    id
    titel
    sumary
    content
  }
}
`;
export const listNews = `
query ListNews(
  $filter: ModelNewFilterInput
  $limit: Int
  $nextToken: String
) {
  listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      titel
      sumary
      content
    }
    nextToken
  }
}
`;
export const getEvent = `
query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    titel
    sumary
    content
  }
}
`;
export const listEvents = `
query ListEvents(
  $filter: ModelNewFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      titel
      sumary
      content
    }
    nextToken
  }
}
`;






