import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InboxIcon from '@mui/icons-material/Inbox';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HouseIcon from '@mui/icons-material/House';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import logo from '../assets/logo.JPG';
import ContactsIcon from '@mui/icons-material/Contacts';
import { blue, green } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { Window } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import Archive from '@mui/icons-material/Archive';
import DefilementContainer from './Partner';





//addUser();
//fetchUser()








const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();

  const menuItems = [
    {
      text: 'Home & Contacts',
      icon: <AddHomeWorkIcon color="secondary" />,
      path: '/',
      disabled:false
    },
    {
      text: 'News',
      icon: <NewspaperIcon color="secondary" />,
      path: '/news',
      disabled:false
    },

    {
      text: 'Jobs',
      icon: <HomeRepairServiceIcon color="secondary" />,
      path: '/jobs',
      disabled:false
    },
    {
      text: 'Events',
      icon: <EventIcon color="secondary" />,
      path: '/event',
      disabled:false
    },
    {
      text: 'Logements',
      icon: <HouseIcon color="secondary" />,
      path: '/house',
      disabled:false
    },
    {
      text: 'Saarland Univerity',
      icon: <SchoolIcon color="secondary" />,
      path: '/university',
      disabled:false
    },
    {
      text: 'Archive',
      icon: <Archive color="secondary" />,
      path: '/archive',
      disabled:true
    },
    {
      text: 'Contacts',
      icon: <ContactsIcon color="secondary" />,
      path: '/contacts',
      disabled:true
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          
          <ListItem key={item.text} disablePadding>
            <ListItemButton disabled={item.disabled}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
    className='screen'
      sx={{
      
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        
        
        
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px )` },
          ml: { sm: `${drawerWidth}px` },
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <span style={{
          margin: 10,
        }}>KGS</span>
        <Link href="/">
          <Box
            component="img"
            sx={{
              maxHeight: 50,
            }}
            alt="Logo"
            src={logo}
          />
        </Link>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className='main'
       component='body'
      >





  
<Toolbar ></Toolbar>


<header>
  
     <div className="defilement-texte">
      <b> <em>Bienvenue à la Communauté camerounaise de Saarbrücken</em></b>
    </div>

</header>



  
  <section>
    <h3 style={{
      backgroundColor:'#9C27B0'
    }}>À propos de nous</h3>
    <p className='p'>
      La kamerunische Gemeinschaft im Saarland e.V. en abrégé KGS,
      est l'association représentant la communauté camerounaise dans les régions de la sarre
      en Allemagne et Lorraine en France. Comptant fièrement plus de 300 membres,
      la kgs s'engage résolument à tisser des liens forts de solidarité parmi ses adhérents, 
      dans le but de faciliter leur vie quotidienne en Allemagne. 
    </p>
  </section> 
  <section>
    <h3 style={{
      backgroundColor:'#9C27B0'
    }}>Notre mission </h3>
    <p className='p'>
      La kgs est une association à but non lucratif dont la mission essentielle est d'accueillir,
      d'intégrer et de guider les camerounais résidant dans la grande région Sarre- Lorraine.
      La kgs s'engage pleinement à faciliter les interactions et les liens entre les camerounais de la dite région et au delà.
      Son objectif  fondamental est d'accompagner ses membres dans leur installation et leur
      épanouissement tout en promouvant un sentiment d'appartenance et de solidarité parmi ses concitoyens.
    </p>
  </section> 


</Box>
<DefilementContainer></DefilementContainer>
</Box>

);

}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;